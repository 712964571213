import { MARK_LINK, render } from "storyblok-rich-text-react-renderer"

import DynamicComponent from "./dynamicComponent"
import { Link } from "gatsby"
import React from "react"

// https://github.com/claus/storyblok-rich-text-react-renderer

const RichText = ({ blok, content, isRTDPage }) => {
	const options = {
		defaultBlokResolver: (name, props) => (
			<DynamicComponent blok={{ component: name, ...props }} />
		),
		defaultStringResolver: str => <span>{str}</span>,
		markResolvers: {
			[MARK_LINK]: (children, props) => {
				const { href, target, linktype } = props
				if (linktype === "email") {
					// Email links: add `mailto:` scheme and map to <a>
					return <a href={`mailto:${href}`}>{children}</a>
				}
				if (href.match(/^(https?:)?\/\//)) {
					// External links: map to <a>
					return (
						<a href={href} target={target}>
							{children}
						</a>
					)
				}
				// Internal links: map to <Link>
				return <Link to={href}>{children}</Link>
			},
		},
	}
	return (
		<div className="prose prose-lg max-w-full break-words">
			{render(content || blok?.content, options)}
		</div>
		// <>
		// 	{blok?.limitWidth ? (
		// 		<div className="py-4 prose prose-lg max-w-full text-sm lg:text-md px-1 md:px-10 lg:px-40 lg:mb-4 ">
		// 			{render(blok?.content || content, options)}
		// 		</div>
		// 	) : (
		// 		<div className="py-4 prose prose-lg max-w-none text-sm lg:text-md mx-2 lg:mb-4">
		// 			{render(blok?.content || content, options)}
		// 		</div>
		// 	)}
		// </>
	)
}

export default RichText
