import { Link } from "gatsby"
import React from "react"
import { SocialIcon } from "react-social-icons"

const NavLink = ({ className, title, link, isSocialLink, children }) => {
	let href = link.linktype === "story" ? `/${link.cached_url}` : link.cached_url

	link?.anchor && (href += `#${link.anchor}`)

	if (href.startsWith("/")) {
		return (
			<Link className={className} to={href}>
				{title || children}
			</Link>
		)
	} else if (isSocialLink) {
		return (
			<SocialIcon
				className={className}
				fgColor="currentColor"
				bgColor="transparent"
				target="_blank"
				rel="noopener noreferrer"
				url={href}
			/>
		)
	} else {
		return (
			<a
				className={className}
				href={href}
				target="_blank"
				rel="noopener noreferrer"
			>
				{title || children}
			</a>
		)
	}
}

export default NavLink
