import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import NavLink from "./navLink"

const Card = ({ blok, isRTDPage }) => {
	const { description, icon, link } = blok
	let href = link.linktype === "story" ? `/${link.cached_url}` : link.cached_url

	return (
		<Link to={href}>
			<div className="h-64 grid text-md text-lg lg:text-xl text-center font-semi-bold text-white bg-primary ">
				<div className="grid w-full text-center  place-items-center h-full ">
					<div className="mt-8 border-4 rounded-full h-28 w-28 flex items-center justify-center border-white">
						<FontAwesomeIcon size="3x" icon={icon} />
					</div>
				</div>
				<NavLink
					className={`text-center pb-12 ml-4 mr-4 mt-8`}
					title={description}
					isSocialLink={false}
					link={link}
				/>
			</div>
		</Link>
	)
}

export default Card
