import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { DocumentDownloadIcon, DocumentIcon } from "@heroicons/react/outline"

const Resource = ({
  resource: { file, fileName, name, permalink, author, authorizedOn },
  useCardStyle,
  clickToDownload = false,
  thumbnailUrl,
}) => {
  
  console.log(authorizedOn,"authorizedOn new feature")

  const { getIdTokenClaims } = useAuth0()
  const [imageLoading, setImageLoading] = useState(true)

  const handleDownload = async response => {
    const contentType = response.headers["content-type"]
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    )
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
  }

  const readBlobText = async ({ response }) => {
    const text = await new Response(response.data).text()
    alert(text)
  }

  const handleClick = async e => {
    e.preventDefault()

    if (!clickToDownload) {
      navigate(`/resources/${permalink}`)
      return
    }

    const tokenClaims = await getIdTokenClaims()

    await axios
      .get(
        `${process.env.GATSBY_API_BASE_URL}/Resources/DownloadResource/${file}`,
        {
          headers: {
            Authorization: `Bearer ${tokenClaims?.__raw}`,
          },
          responseType: "blob",
        }
      )
      .then(handleDownload)
      .catch(readBlobText)
  }

  const ResourceCard = () => {
	const containerRef = useRef(null);
  
	return (
	  <div className="p-2 cursor-pointer border border-gray-300 resource-card-container product-wrapper transform transition-transform transition-all
    hover:scale-105 hover:shadow-lg items-center ">
      <div className="resource-card" onClick={handleClick}>
        <div
          className="bg-gray-200 relative overflow-hidden w-auto w-full h-60 justify-center "
          ref={containerRef}
        >
        {thumbnailUrl ? (
          <img
            className="object-scale-down w-full w-auto h-full"
            src={thumbnailUrl}
            alt=""
          />
        ) : (
          <div className="flex items-center justify-center text-neutral w-full h-full text-gray-500">
            <DocumentIcon className="w-12 h-12" /> {/* File icon */}
          </div>
        )}
        </div>
        <div className="card-content text-gray-500">
        <p>
          {author} {author ? '|' : ''} 
          { authorizedOn ? new Intl.DateTimeFormat("en-AU", {
            timeZone: "Australia/NSW",
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(new Date(authorizedOn)) : ''}
        </p>
        </div>
        <h1 className="text-md text-neutral font-bold">{name}</h1>
      </div>
	  </div>
	);
  };

  if (useCardStyle) {
    return <ResourceCard />;
  }

  return (
    <div>
      <a className="text-blue-500" href="" onClick={handleClick}>
        {name}
      </a>
    </div>
  )
}

export default Resource;
