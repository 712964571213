import CallToAction from "./callToAction"
import NavLink from "./navLink"
import React from "react"

const Hero = ({
	blok: {
		title,
		subtitle,
		image,
		image_x_offset,
		call_to_action_btn_text,
		call_to_action_btn_link,
	},
}) => {
	return (
		<div
			className="relative my-0.5 mx-0 bg-neutral overflow-hidden w-full"
			id="hero-banner"
		>
			<div className="w-full mx-0">
				<div className="relative pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
					<main className="mt-10 mx-0 max-w-full sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
						<div className="text-center lg:text-left text-white mx-4 lg:ml-12 xl:ml-40">
							<Title title={title} />
							<Subtitle subtitle={subtitle} />
							<CallToAction
								text={call_to_action_btn_text}
								link={call_to_action_btn_link}
							/>
						</div>
					</main>
				</div>
			</div>
			<HeroImage
				image={image}
				xOffset={{ objectPosition: `${image_x_offset}px center` }}
			/>
		</div>
	)
}

const Title = ({ title }) =>
	title ? (
		<h1 className="text-4xl tracking-tight font-bold sm:text-5xl md:text-6xl">
			{title}
		</h1>
	) : (
		<></>
	)

const Subtitle = ({ subtitle }) =>
	subtitle ? (
		<p className="mt-3 text-base font-light sm:mt-5 sm:text-lg sm:max-w-md sm:mx-auto md:mt-5 md:text-base lg:mx-0">
			{subtitle}
		</p>
	) : (
		<></>
	)

const HeroImage = ({ image, xOffset }) =>
	image ? (
		<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
			<img
				className="hero-img h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
				style={xOffset}
				src={image.filename}
				alt={image.alt}
			/>
		</div>
	) : (
		<></>
	)

export default Hero
