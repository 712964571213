import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon, FilterIcon } from "@heroicons/react/outline";
import Disclosure from "./disclosure";

const ResourceFilter = ({ setSelectedFilters, setSelectedQuries, selectedFilters, selectedQuries, filterOptions }) => {
	const [disclosureState, setDisclosureState] = useState(0);

    const isAnyFilterApplied =
		selectedFilters.authors.length > 0 ||
		selectedFilters.dates.length > 0 ||
		selectedFilters.topics.length > 0
    
    const handleDisclosureChange = (state) => {
		if (state === disclosureState) {
			setDisclosureState(0); // close all of them
		} else {
			setDisclosureState(state); // open the clicked disclosure
		}
	}

    const filterByQuery = (query, collection) => {
		return query === ""
			? [null, collection].flat()
			: [null, collection.filter(obj => {
					return obj.toLowerCase().includes(query.toLowerCase())
				})].flat()
	}

    const resetFilters = () => {
        setSelectedQuries({authors: '', topics: '', dates: ''})
		setSelectedFilters({authors: [], topics: [], dates: []})
    }

    return (
        <Popover className="relative w-1/6">
            {({ open, close }) => (
                <>
                    <Popover.Button as="Component" onClick={() => close()} className="hidden xl:inline-flex w-full justify-center bg-neutral text-white items-center p-2 border border-white-300 rounded-md">
                        Filter
                        <ChevronDownIcon className={open ? 'rotate-180 transform w-5' : 'w-5'} />
                    </Popover.Button>

                    <Popover.Button className="xl:hidden inline-flex w-full h-full justify-center bg-neutral text-white items-center p-0 border border-white-300 rounded-md">
                        <FilterIcon className='w-6 h-6 sm:h-8' />
                    </Popover.Button>

                    <Popover.Panel
                        className='-left-64 top-full absolute justify-center mt-2 w-80 z-10 rounded-md shadow-lg bg-white ring-1 ring-white ring-opacity-5'
                    >
                        <div className="py-1">
                            <Disclosure 
                                number={1}
                                label="Topics"
                                handleDisclosureChange={handleDisclosureChange}
                                disclosureState={disclosureState}
                                setQuery={(a) => setSelectedQuries({...selectedQuries, topics: a})}
                                query={selectedQuries.topics}
                                selected={selectedFilters.topics}
                                setSelected={(a) => setSelectedFilters({...selectedFilters, topics: a})}
                                options={filterByQuery(
                                    selectedQuries.topics,
                                    filterOptions.topics
                                )}
                            />
                            <Disclosure 
                                number={2}
                                label="Years"
                                handleDisclosureChange={handleDisclosureChange}
                                disclosureState={disclosureState}
                                setQuery={(a) => setSelectedQuries({...selectedQuries, dates: a})}
                                query={selectedQuries.dates}
                                selected={selectedFilters.dates}
                                setSelected={(a) => setSelectedFilters({...selectedFilters, dates: a})}
                                options={filterByQuery(
                                    selectedQuries.dates,
                                    filterOptions.dates
                                )}
                            />
                            <Disclosure 
                                number={3}
                                label="Authors"
                                handleDisclosureChange={handleDisclosureChange}
                                disclosureState={disclosureState}
                                setQuery={(a) => setSelectedQuries({...selectedQuries, authors: a})}
                                query={selectedQuries.authors}
                                selected={selectedFilters.authors}
                                setSelected={(a) => setSelectedFilters({...selectedFilters, authors: a})}
                                options={filterByQuery(selectedQuries.authors, filterOptions.authors)}
                            />

                            <div className="flex items-center justify-center mt-1">
                                {isAnyFilterApplied && (
                                    <button
                                        onClick={() => {
                                            resetFilters()
                                            close()
                                        }}
                                        className="bg-neutral text-white px-4 rounded-md shadow-sm py-1 mr-2"
                                    >
                                        Reset
                                    </button>
                                )}
                                <button
                                    onClick={close} // Close the popover
                                    className="bg-neutral text-white px-4 rounded-md shadow-sm  py-1 close"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};

export default ResourceFilter;
