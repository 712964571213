import DynamicComponent from "./dynamicComponent"
import React from "react"

const Table = ({ blok }) => {
	const { ShowLines } = blok
	return (
		<div className="flex flex-col">
			<div className="overflow-x-auto w-full">
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow overflow-hidden border-b border-gray-200 ">
						<table className="min-w-full divide-y divide-gray-200">
							<tbody>
								{blok?.tableRows.map(r => (
									<TableRow key={r._uid} blok={r} ShowLines={ShowLines} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

const TableRow = ({ blok, ShowLines }) => {
	return (
		<tr className={`max-w-full ${ShowLines ? "border" : ""}`}>
			{blok?.tableColumns.map(c => (
				<TableColumn key={c._uid} blok={c} ShowLines={ShowLines} />
			))}
		</tr>
	)
}

const TableColumn = ({ blok, ShowLines }) => {
	return (
		<td className={`max-w-full  ${ShowLines ? "border" : ""}`}>
			{blok?.content.map(c => (
				<DynamicComponent key={c._uid} blok={c} />
			))}
		</td>
	)
}

export default Table
