import React from "react";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon, XIcon } from "@heroicons/react/outline";

const Disclosure = ({ label, setQuery, query, selected, setSelected, number, disclosureState, handleDisclosureChange, options }) => {
    return (
        <>
            <button
                onClick={() => handleDisclosureChange(number)}
                className='block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center w-full'
            >
                {disclosureState === number ?
                    <ChevronDownIcon className="h-5 w-5" />
                    : <ChevronRightIcon className="h-5 w-5" />
                }
                <span className="ml-3 text-base font-medium">
                    {label}
                </span>
            </button>
            {disclosureState === number && <div className="pl-4 pt-2 pr-3">
                <Combobox
                    value={selected}
                    onChange={setSelected}
                    multiple
                >
                    <Combobox.Input
                        placeholder={`Search ${label}...`}
                        className="w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={event => setQuery(event.target.value)}
                        value={query}
                    />
                    <div className="max-h-40 overflow-auto">
                        {options.map((option) => (
                            <Combobox.Option
                                className="cursor-pointer hover:bg-gray-200 block p-2"
                                key={option}
                                value={option}
                            >
                                 {({ active }) => (
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={selected.includes(option)}
                                            onChange={() => {
                                                if (selected.includes(option)) {
                                                    setSelected(selected.filter((element) => element !== option));
                                                } else {
                                                    setSelected([...selected, option]);
                                                }
                                                // setQuery('');
                                            }}
                                            className="mr-2"
                                        />
                                        <span className={`px-2 py-1 rounded ${active && 'border border-indigo-600'} ${selected.includes(option) ? 'text-gray-800' : 'text-gray-600'}`}>
                                            {option || `No ${label}`}
                                        </span>
                                    </div>
                                )}
                            </Combobox.Option>
                        ))}
                    </div>
                </Combobox>
            </div>}
        </>
    );
};

export default Disclosure;
