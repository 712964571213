import RichText from "./richText"

const ImageText = ({ blok }) => {
	const image = blok.image
	const richText = blok.text
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 place-content-center place-items-center gap-8 m-12 place-items-center ">
			<div className="w-full">
				<img src={image.filename} alt={image.alt} className="h-full w-full" />
			</div>
			<div className="">
				<RichText blok={blok} content={richText} />
			</div>
		</div>
	)
}

export default ImageText
