import DynamicComponent from "./dynamicComponent"
import React from "react"

const AdjustableComponent = ({ blok, isRTDPage }) => {
	const { FullCSSControl, EasyClasses } = blok
	if (FullCSSControl) {
		return (
			<div className={FullCSSControl}>
				{blok.Block?.map(b => (
					<DynamicComponent blok={b} key={b._uid} isRTDPage={isRTDPage} />
				))}
			</div>
		)
	} else if (EasyClasses) {
		return (
			<div className={EasyClasses}>
				{blok.Block?.map(b => (
					<DynamicComponent blok={b} key={b._uid} isRTDPage={isRTDPage} />
				))}
			</div>
		)
	} else {
		return (
			<div>
				{blok.Block?.map(b => (
					<DynamicComponent blok={b} key={b._uid} isRTDPage={isRTDPage} />
				))}
			</div>
		)
	}
}

export default AdjustableComponent
