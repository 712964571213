import * as React from "react"
import tw, { styled } from "twin.macro"
import DynamicComponent from "./dynamicComponent"

const columnNames = {
	1: tw`grid-cols-1`,
	2: tw`grid-cols-2`,
	3: tw`grid-cols-3`,
	4: tw`grid-cols-4`,
	5: tw`grid-cols-5`,
	6: tw`grid-cols-6`,
	7: tw`grid-cols-7`,
	8: tw`grid-cols-8`,
	9: tw`grid-cols-9`,
	10: tw`grid-cols-10`,
	11: tw`grid-cols-11`,
	12: tw`grid-cols-12`,
}
const StyledGrid = styled.div(({ numberOfColumns }) => [
	tw`grid grid-flow-col auto-cols-max gap-4`, // Default to display items horizontally
	columnNames[numberOfColumns],
])

const Grid = ({ blok }) => {
	const [isMobile, setIsMobile] = React.useState(false)

	React.useEffect(() => {
		const updateNumberOfColumns = () => {
			setIsMobile(window.innerWidth < 1000)
		}

		updateNumberOfColumns()
		window.addEventListener("resize", updateNumberOfColumns)

		return () => {
			// Cleanup the event listener on component unmount
			window.removeEventListener("resize", updateNumberOfColumns)
		}
	}, [])

	return (
		<>
			{isMobile ? (
				<>
					{blok.columns.map(b => (
						<DynamicComponent blok={b} key={b._uid} />
					))}
				</>
			) : (
				<StyledGrid numberOfColumns={blok.number_of_columns || 1}>
					{blok.columns.map(b => (
						<DynamicComponent blok={b} key={b._uid} />
					))}
				</StyledGrid>
			)}
		</>
	)
}

export default Grid
