import React from "react"

const Undefined = ({ name, props }) => (
	<div>
		<code>Missing blok resolver for blok type "{name}".</code>
		<pre>
			<code>{JSON.stringify(props, undefined, 2)}</code>
		</pre>
	</div>
)

export default Undefined
