const HomePageImage = ({ blok, isRTDPage }) => {
	const { content } = blok

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ")
	}

	return (
		<img
			className={classNames(
				isRTDPage ? "lg:h-96" : "md:h-60 ",
				"w-full h-40 lg:h-80 object-cover object-center"
			)}
			src={content.filename}
		></img>
	)
}

export default HomePageImage
