import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MoreInformation = ({ blok, isRTDPage }) => {
	const { icon, text, email, telephone } = blok

	return (
		<div className="  bg-primary flex flex-wrap justify-center items-center h-full max-w-full">
			<div className="md:h-40 ml-2 mr-2 md:ml-24 md:mr-24 flex flex-row text-white text-center md:text-left  justify-center items-center justify-evenly">
				<FontAwesomeIcon size="6x" icon={icon} />
				<p className="ml-12 flex-grow text-white font-light  font-base md:text-2xl">
					{text}
					<a href={`mailto:${email}`}>
						<strong>{email}</strong>
					</a>{" "}
					or on{" "}
					<a href={`tel:+${telephone}`}>
						<strong>{telephone}</strong>
					</a>
				</p>
			</div>
		</div>
	)
}

export default MoreInformation
