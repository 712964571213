import tw, { styled } from "twin.macro"

import NavLink from "./navLink"
import React from "react"

const tileBlocks = {
	1: tw`md:row-start-1 md:col-span-2`,
	2: tw`md:row-start-1 md:col-span-3`,
	3: tw`md:row-start-2 md:col-span-3 md:col-start-1`,
	4: tw`md:row-start-2 md:col-span-2`,
	5: tw`row-auto`,
}

const calculateClass = (numberOfTiles, i) => {
	return tileBlocks[i + 1]
}

const StyledTile = styled.div(({ DynamicRows, backgroundImage }) => [
	tw`w-full h-full`,
	calculateClass(DynamicRows[0], DynamicRows[1]),
	backgroundImage ? "" : tw`bg-primary `,
])

const Tile = ({ blok, DynamicRows }) => {
	const title = blok?.title
	const abstract = blok?.abstract
	const callToActionText = blok?.callToActionText
	const link = blok?.callToActionLink
	const backgroundImage = blok.backgroundImage?.filename

	return (
		<StyledTile DynamicRows={DynamicRows} backgroundImage={backgroundImage}>
			{backgroundImage ? (
				<div className="relative overflow-hidden h-full max-h-96">
					<img className="object-cover w-full h-full " src={backgroundImage} />
					<div className="bottom-0 absolute z-40 grid grid-rows-3 text-center  place-items-center h-full">
						<h1 className="mb-1 md:mb-8 text-white font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl  ">
							{title}
						</h1>
						<div className="text-white mb-8 ml-8 mr-8">{abstract}</div>
						<NavLink
							className={`mb-8 mt-8 bottom-0 no-underline w-1/2 flex items-center justify-center px-8 py-3 border border-transparent text-primary font-medium rounded bg-white hover:opacity-80 md:py-4 md:text-lg md:px-10`}
							title={callToActionText}
							isSocialLink={false}
							link={link}
						/>
					</div>
				</div>
			) : (
				<div className="grid grid-rows-3 text-center  place-items-center h-full max-h-96">
					<h1 className="mb-1 md:mb-8 text-white font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl  ">
						{title}
					</h1>
					<div className="text-white mb-8 ml-8 md:ml-24 mr-8 md:mr-24">
						{abstract}
					</div>
					<NavLink
						className={`mb-8 mt-8 bottom-0 no-underline w-1/2 flex items-center justify-center px-8 py-3 border border-transparent text-primary font-medium rounded bg-white hover:opacity-80 md:py-4 md:text-lg md:px-10`}
						title={callToActionText}
						isSocialLink={false}
						link={link}
					/>
				</div>
			)}
		</StyledTile>
	)
}

export default Tile
