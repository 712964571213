import React, { useState } from "react"
import { StaticQuery, graphql, navigate, useStaticQuery } from "gatsby"

import { SearchIcon } from "@heroicons/react/solid"
import { useFlexSearch } from "react-use-flexsearch"

export default function Search() {
	const queryData = useStaticQuery(graphql`
		query {
			localSearchSearch {
				index
				store
			}
		}
	`)

	const index = queryData.localSearchSearch.index
	const store = queryData.localSearchSearch.store
	const [query, setQuery] = useState("")

	const results = useFlexSearch(query, index, store)

	const handleChange = e => {
		setQuery(e.target.value)
	}

	const handleSubmit = e => {
		e.preventDefault()
		navigate("/search-results/", {
			state: { searchResults: results, search: query },
		})
	}

	return (
		<div className="flex justify-center">
			<div className="max-w-lg w-full lg:max-w-xs">
				<label htmlFor="search" className="sr-only">
					Search
				</label>
				<div className="relative text-white hover:text-primary focus-within:text-gray-400">
					<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
					</div>
					<form onSubmit={handleSubmit} className="">
						<input
							type="text"
							name="searchValue"
							value={query}
							onChange={handleChange}
							className="block w-full bg-neutral py-2 pl-10 pr-3 border border-transparent rounded leading-5 placeholder-sky-100 focus:outline-none focus:border-white focus:placeholder-gray-500 focus:text-gray-900 focus:bg-white sm:text-sm"
						/>
					</form>
				</div>
			</div>
		</div>
	)
}
