import { DeviceMobileIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid"
import React from "react"
import { EmailIcon } from "react-share"

const ContactDetails = ({
	blok: {
		name,
		email,
		phone,
		header,
		mobile,
		show_map,
		address_city,
		company_name,
		address_state,
		address_street,
		address_street2,
		address_postcode,
	},
}) => (
	<div>
		<h3>{header}</h3>
		<p>{name}</p>
		<p>
			<GoogleMapsLink
				showMapLink={show_map}
				addressComponents={[
					address_street,
					address_city,
					address_state,
					address_postcode,
				]}
			>
				<RenderLine content={[company_name]} />
				<RenderLine content={[address_street]} />
				<RenderLine content={[address_street2]} />
				<RenderLine content={[address_city, address_state, address_postcode]} />
			</GoogleMapsLink>
			<RenderContact protocol="tel" contact={phone} icon="PhoneIcon" />
			<RenderContact protocol="tel" contact={mobile} icon="DeviceMobileIcon" />
			<RenderContact protocol="mailto" contact={email} icon="MailIcon" />
		</p>
	</div>
)

const GoogleMapsLink = ({ addressComponents, showMapLink, children }) => {
	if (!showMapLink) {
		return children
	}
	const query = [...addressComponents, "au"].filter(x => !!x).join(",")
	return (
		<a href={`http://maps.google.com/?q=${encodeURI(query)}`} target="_blank">
			{children}
		</a>
	)
}

const RenderLine = ({ content }) => {
	const filteredContent = content.filter(c => !!c)
	if (filteredContent.length) {
		return (
			<>
				{filteredContent.join(" ")} <br />
			</>
		)
	}
	return null
}

const RenderContact = ({ protocol, contact, icon }) => {
	if (!contact) {
		return null
	}
	return (
		<>
			<span>
				{icon === "PhoneIcon" ? (
					<PhoneIcon
						className="h-6 w-6 text-gray-400 inline-block"
						aria-hidden="true"
					/>
				) : icon === "DeviceMobileIcon" ? (
					<DeviceMobileIcon
						className="h-6 w-6 text-gray-400 inline-block"
						aria-hidden="true"
					/>
				) : icon === "MailIcon" ? (
					<MailIcon
						className="h-6 w-6 text-gray-400 inline-block"
						aria-hidden="true"
					/>
				) : (
					<></>
				)}
				<span className="ml-3 text-base text-gray-500">
					<a href={`${protocol}:${contact}`}>{contact}</a>
				</span>
			</span>
			<br />
		</>
	)
}

export default ContactDetails
