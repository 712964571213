import { graphql, useStaticQuery } from "gatsby"

import React from "react"
import Resource from "../resources/resource"

const ResourceItem = ({ blok }) => {
	const resourceId = blok?.ResourceId

	const data = useStaticQuery(graphql`
		{
			allFreeResources {
				nodes {
					name
					fileName
					description
					author
					authorizedOn
					categories
					file
					permalink
					thumbnail {
						thumbnailUrl
						url
					}
				}
			}
		}
	`)

	//Find the resource by id
	const resource = data.allFreeResources.nodes.find(r => r.file === resourceId)

	return <Resource resource={resource} useCardStyle={true} />
}

export default ResourceItem
