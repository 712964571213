import DynamicComponent from "./dynamicComponent"

const CenterText = ({ blok, isRTDPage }) => {
	const title = blok.title
	const dynamic = blok.text

	// console.log(blok)
	return (
		<div className="flex flex-col justify-center items-center h-full max-w-full text-center ml-6 mr-6 md:ml-0 md:mr-0 md:text-left">
			<h1 className=" mt-0 md:mt-8 mb-1 md:mb-8 text-info font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl  ">
				{title}
			</h1>
			{/* {dynamic?.map(b => (
				<DynamicComponent blok={b} key={b._uid} isRTDPage={isRTDPage} />
			))} */}
			{dynamic &&
				Array.isArray(dynamic) &&
				dynamic.map(b => (
					<DynamicComponent blok={b} key={b._uid} isRTDPage={isRTDPage} />
				))}
		</div>
	)
}

export default CenterText
