import tw, { styled } from "twin.macro"

import React from "react"
import Tile from "./tile"

const tiles = {
	1: tw`grid-cols-1 grid-rows-1 `,
	2: tw`md:grid-cols-5 md:grid-rows-2 grid-cols-1 grid-rows-2`,
	3: tw`md:grid-cols-5 md:grid-rows-2 grid-cols-1 grid-rows-3`,
	4: tw`md:grid-cols-5 md:grid-rows-2 grid-cols-1 grid-rows-4`,
}

const StyledTileBlock = styled.div(({ numberOfTiles }) => [
	tw`grid md:grid-flow-col gap-4 place-content-center place-items-center`,
	tiles[numberOfTiles],
])

const TileBlock = ({ blok }) => {
	return (
		<div className="bg-gray-300 min-h-screen ">
			<div className="md:flex md:flex-wrap md:justify-center md:items-center md:h-full max-w-full md:ml-24 md:mr-24 md:pt-24 md:pb-24">
				<StyledTileBlock numberOfTiles={blok.Tiles.length}>
					{blok.Tiles.map((b, i) => (
						<Tile blok={b} key={b._uid} DynamicRows={[blok.Tiles.length, i]} />
					))}
				</StyledTileBlock>
			</div>
		</div>
	)
}

export default TileBlock
