import * as React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/outline"

import NavLink from "../storyblok/navLink"
import StaticContent from "../storyblok/staticContent"

const FooterGroup = ({ navGroup: { title, navLinks } }) => (
	<div className="w-full flex flex-col items-center md:items-start">
		<span className="footer-title">{title}</span>
		{navLinks.map(item => (
			<NavLink key={item._uid} className="link link-hover" {...item} />
		))}
	</div>
)

export default function Footer({ isRTDPage }) {
	const footer = useStaticQuery(graphql`
		{
			rtd: storyblokEntry(slug: { eq: "_rtdfooter" }) {
				name
				content
			}
			ipwea: storyblokEntry(slug: { eq: "_footer" }) {
				name
				content
			}
		}
	`)

	const { navGroups, asideGroup } = JSON.parse(
		isRTDPage ? footer.rtd.content : footer.ipwea.content
	)
	//Todo: Change Footer Colors
	return isRTDPage ? (
		<div data-theme="rtdTheme">
			<footer className="pt-4 md:p-10 footer bg-neutral text-neutral-content w-full">
				{navGroups.map(g => (
					<FooterGroup key={g._uid} navGroup={g} />
				))}
				<div className="md:place-self-top justify-self-center md:justify-self-center ">
					<div className="grid grid-flow-col md:gap-4">
						{asideGroup.map(link => (
							<NavLink
								key={link._uid}
								className="link link-hover hover:opacity-80"
								{...link}
							/>
						))}
					</div>
				</div>
			</footer>
			<footer className="px-10 py-4 footer bg-neutral text-neutral-content">
				<div className="md:flex justify-self-center md:items-center md:justify-self-start">
					<p className="text-xs">
						&copy; {new Date().getFullYear()} IPWEA (NSW){" "}
						<span className="ml-4">
							All prices are in Australian Dollars ($AUD)
						</span>
					</p>
				</div>
			</footer>
		</div>
	) : (
		<>
			<footer className="md:p-10 footer bg-neutral text-neutral-content">
				{navGroups.map(g => (
					<FooterGroup key={g._uid} navGroup={g} />
				))}
				<div className="md:w-auto w-full md:place-self-top justify-self-start md:justify-self-center">
					<div className="md:w-auto w-full grid grid-flow-col md:gap-4 justify-items-center">
						{asideGroup.map(link => (
							<NavLink
								key={link._uid}
								className="link link-hover hover:opacity-80"
								{...link}
							/>
						))}
					</div>
				</div>
			</footer>
			<footer className="md:px-5 py-4 footer bg-neutral text-neutral-content text-xs flex md:flex-nowrap flex-wrap md:gap-4 gap-2 justify-center md:justify-start ">
				<p className="shrink">
					<StaticContent id="footertextone" />
				</p>
				<p className="shrink">&copy; {new Date().getFullYear()} IPWEA (NSW)</p>
				<p className="shrink">
					<StaticContent id="footertexttwo" />
				</p>
			</footer>
		</>
	)
}
