import { ChevronDownIcon, SearchIcon } from "@heroicons/react/solid"
import { Disclosure, Menu, Popover, Transition } from "@headlessui/react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import React, { Fragment } from "react"

import IpweaLogo from "../../assets/ipweaLogo.svg"
import LocalGovNSWLogo from "../../assets/local-government-nsw.svg"
import NavLink from "../storyblok/navLink"
import RTDLogo from "../../assets/rtdlogo.svg"
import Search from "../search"
import { useAuth0 } from "@auth0/auth0-react"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const LoginControls = ({ isMobileControl }) => {
	const { isAuthenticated, isLoading, loginWithRedirect, logout, user } =
		useAuth0()

	if (isLoading) {
		return null
	}

	if (isAuthenticated) {
		return isMobileControl ? (
			<>
				<Link to="/myprofile" className="px-4 py-2 w-full">
					<img
						className="inline-block h-10 w-10 rounded-full mr-2"
						src={user.picture}
						alt="Avatar"
					/>
					My Profile
				</Link>
				<button
					onClick={logout}
					className="w-full flex btn btn-primary justify-center text-base"
				>
					Sign Out
				</button>
			</>
		) : (
			<>
				<Menu as="div" className="m-auto">
					<div>
						<Menu.Button className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 ">
							<img
								className="inline-block flex-none h-10 w-10 rounded-full"
								src={user.picture}
								alt="Avatar"
							/>
						</Menu.Button>
					</div>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="origin-top-right absolute rounded-md shadow-lg bg-white border border-solid z-50">
							<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<Link
											to="/myprofile"
											className={classNames(
												active ? "bg-gray-100 text-gray-900" : "text-gray-700",
												"block px-4 py-2 text-sm"
											)}
										>
											Profile
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
											onClick={logout}
											className={classNames(
												active ? "bg-gray-100 text-gray-900" : "text-gray-700",
												"block px-4 py-2 text-sm"
											)}
										>
											Sign Out
										</button>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			</>
		)
	}

	return isMobileControl ? (
		<>
			<button
				onClick={loginWithRedirect}
				className=" sm:inline-block w-full flex items-center justify-center px-4 py-1 border border-transparent rounded shadow-sm text-base font-medium text-white bg-primary hover:opacity-80"
			>
				SIGN IN
			</button>
		</>
	) : (
		<>
			<button
				onClick={loginWithRedirect}
				className="hidden sm:inline-block ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-1 border border-transparent rounded shadow-sm text-base font-medium text-white bg-primary hover:opacity-80"
			>
				SIGN IN
			</button>
		</>
	)
}

const HeaderGroup = ({
	navGroup: { title, navLinks, link },
	isMobileControl,
}) => {
	if (isMobileControl) {
		return (
			<>
				{navLinks.length < 1 ? (
					<NavLink
						link={link}
						isSocialLink={false}
						title={title}
						className={"ml-3 text-base font-medium text-gray-900"}
					/>
				) : (
					<Disclosure>
						{({ open }) => (
							<>
								<Disclosure.Button
									className={classNames(
										open ? "bg-gray-50" : "",
										"-m-3 p-3 flex items-center rounded-t-md"
									)}
								>
									<ChevronDownIcon
										className={classNames(
											open
												? "text-gray-600"
												: "text-gray-400 transform -rotate-90",
											"ml-2 h-5 w-5 group-hover:text-gray-500"
										)}
									/>

									<span className="ml-3 text-base font-medium text-gray-900">
										{title}
									</span>
								</Disclosure.Button>
								<Disclosure.Panel className="rounded-b-md text-gray-500">
									{navLinks.map(item => (
										<NavLink
											key={item._uid}
											className="-m-3 p-3 flex items-start rounded-lg"
											{...item}
										/>
									))}
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				)}
			</>
		)
	}

	return (
		<>
			{navLinks.length < 1 ? (
				<>
					<NavLink
						link={link}
						isSocialLink={false}
						title={title}
						className={
							"group bg-neutral rounded-md inline-flex items-center text-white font-medium hover:text-primary focus:outline-none"
						}
					/>
				</>
			) : (
				<Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button
								className={classNames(
									open ? "text-white" : "text-white",
									"group bg-neutral rounded-md inline-flex items-center text-base font-medium hover:text-primary focus:outline-none"
								)}
							>
								<span>{title}</span>
								<ChevronDownIcon
									className={classNames(
										open ? "text-white" : "text-white",
										"ml-2 h-5 w-5 group-hover:text-primary"
									)}
									aria-hidden="true"
								/>
							</Popover.Button>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
									<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
										<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
											{navLinks.map(item => (
												<NavLink
													key={item._uid}
													className="-m-3 p-3 flex items-start rounded-lg cursor-pointer hover:bg-gray-50"
													{...item}
												/>
											))}
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
			)}
		</>
	)
}

export default function Header({ isRTDPage }) {
	const header = useStaticQuery(graphql`
		{
			rtd: storyblokEntry(slug: { eq: "_rtdheader" }) {
				name
				content
			}
			ipwea: storyblokEntry(slug: { eq: "_header" }) {
				name
				content
			}
		}
	`)

	const { navGroups, asideGroup } = isRTDPage
		? JSON.parse(header.rtd.content)
		: JSON.parse(header.ipwea.content)

	return isRTDPage ? (
		<div data-theme="rtdTheme">
			<div className="h-16 md:h-28 lg:h-32 flex justify-between items-center px-4">
				<div data-theme="mytheme" className="flex justify-start xl:w-0 ">
					<Link to="/">
						<IpweaLogo className="h-10 w-auto md:h-16 lg:h-24 py-1 mr-2" />
					</Link>
					<a href="https://www.lgnsw.org.au/">
						<LocalGovNSWLogo className="h-10 w-auto md:h-16 lg:h-24 py-1 mr-2" />
					</a>
				</div>
				<div className="flex justify-around items-center">
					<div className="hidden sm:block">
						<div className="grid grid-flow-col gap-4 fill-current text-neutral hidden sm:inline-block">
							{asideGroup.map(link => (
								<NavLink
									key={link._uid}
									className="link link-hover hover:opacity-80"
									{...link}
								/>
							))}
						</div>
					</div>
					<div className="flex-none items-center ">
						<LoginControls isMobileControl={false} />
					</div>
				</div>
			</div>

			<Popover className="relative bg-neutral">
				<div className="max-w-10/12 mx-0.5 align-center px-4 sm:px-6 bg-neutral">
					<div className="flex justify-between md:mx-8 lg:mx-24 xl:ml-24 xl:mr-1 xl:justify-start items-center py-6 space-x-10 ">
						<div className="flex xl:hidden justify-center">
							<Search />
						</div>
						<div className="-mr-2 -my-2 xl:hidden">
							<Popover.Button className="bg-neutral text-white rounded-md p-2 inline-flex items-center justify-center hover:text-primary focus:outline-none">
								<span className="sr-only">Open menu</span>
								<MenuIcon
									className="h-6 w-6 text-white hover:text-primary hover:opacity-80"
									aria-hidden="true"
								/>
							</Popover.Button>
						</div>
						<Popover.Group as="nav" className="hidden xl:flex space-x-10">
							{navGroups.map(g => (
								<HeaderGroup
									key={g._uid}
									navGroup={g}
									isMobileControl={false}
								/>
							))}
						</Popover.Group>
						<div className="hidden xl:flex items-center xl:flex-1 lg:w-0 xl:justify-end">
							<Search />
						</div>
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right xl:hidden">
						<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
							<div className="pt-5 pb-6 px-5">
								<div className="flex items-center justify-between">
									<div>
										<IpweaLogo className="h-8 w-auto" />
									</div>
									<div className="-mr-2">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-neutral">
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
								<div className="mt-6">
									<nav className="grid gap-y-8">
										{navGroups.map(g => (
											<HeaderGroup
												key={g._uid}
												navGroup={g}
												isMobileControl={true}
											/>
										))}
									</nav>
								</div>
							</div>
							<div className="py-6 px-5 space-y-6">
								<LoginControls isMobileControl={true} />
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	) : (
		<>
			<div className="h-16 md:h-28 lg:h-32 flex justify-between items-center px-4 md:px-8 lg:px-40">
				<div className="flex justify-start xl:w-0 ">
					<Link to="/">
						<IpweaLogo className="h-10 w-auto md:h-16 lg:h-24 py-1 mr-2" />
					</Link>
				</div>
				<div className="flex justify-around items-center">
					<div className="">
						<div className="grid grid-flow-col gap-4 fill-current text-neutral hidden sm:inline-block">
							{asideGroup.map(link => (
								<NavLink
									key={link._uid}
									className="link link-hover hover:opacity-80"
									{...link}
								/>
							))}
						</div>
					</div>
					<div className="flex-none items-center ">
						<LoginControls isMobileControl={false} />
					</div>
				</div>
			</div>

			<Popover className="relative bg-neutral">
				<div className="max-w-10/12 mx-0.5 align-center px-4 sm:px-6 bg-neutral">
					<div className="flex justify-between md:mx-8 lg:mx-24 xl:ml-24 xl:mr-1 xl:justify-start items-center py-6 space-x-10 ">
						<div className="flex xl:hidden justify-center">
							<Search />
						</div>
						<div className="-mr-2 -my-2 xl:hidden">
							<Popover.Button className="bg-neutral text-white rounded-md p-2 inline-flex items-center justify-center hover:text-primary focus:outline-none">
								<span className="sr-only">Open menu</span>
								<MenuIcon
									className="h-6 w-6 text-white hover:text-primary hover:opacity-80"
									aria-hidden="true"
								/>
							</Popover.Button>
						</div>
						<Popover.Group as="nav" className="hidden xl:flex space-x-10">
							{navGroups.map(g => (
								<HeaderGroup
									key={g._uid}
									navGroup={g}
									isMobileControl={false}
								/>
							))}
						</Popover.Group>
						<div className="hidden xl:flex items-center xl:flex-1 lg:w-0 xl:justify-end">
							<Search />
						</div>
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right xl:hidden">
						<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
							<div className="pt-5 pb-6 px-5">
								<div className="flex items-center justify-between">
									<div>
										<IpweaLogo className="h-8 w-auto" />
									</div>
									<div className="-mr-2">
										<Popover.Button
											tabIndex="-1"
											className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-neutral"
										>
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
								<div className="mt-6">
									<nav className="grid gap-y-8">
										{navGroups.map(g => (
											<HeaderGroup
												key={g._uid}
												navGroup={g}
												isMobileControl={true}
											/>
										))}
									</nav>
								</div>
							</div>
							<div className="py-6 px-5 space-y-6">
								<LoginControls isMobileControl={true} />
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</>
	)
}
