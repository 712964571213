import React, { useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import RichText from "./richText"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const ExpandablePanel = ({ blok }) => {
	const { header, content } = blok
	const [open, setOpen] = useState(false)
	return (
		<div className="py-3">
			<h3
				className="text-2xl text-gray-600 cursor-pointer"
				onClick={() => setOpen(!open)}
			>
				<ChevronDownIcon
					className={classNames(
						open ? "" : "transform -rotate-90",
						"h-5 w-5 inline"
					)}
				/>
				<span className="ml-3">{header}</span>
			</h3>
			{open && <RichText content={content} />}
		</div>
	)
}

export default ExpandablePanel
