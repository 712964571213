import IpweaLogo from "../../assets/ipweaLogo.svg"
import { Link } from "gatsby"
import LocalGovNSWLogo from "../../assets/local-government-nsw.svg"
import RichText from "./richText"

const AboutUs = ({ blok }) => {
	const title = blok.Title
	const abstract = blok.Abstract
	const displayLogos = blok.displayLogos

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 place-content-center place-items-center gap-8 m-12 place-items-center ">
			<div className="md:ml-12">
				<h1 className="mb-1 md:mb-8 text-info font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl">
					{title}
				</h1>
				<RichText blok={blok} content={abstract} />
			</div>

			<div className="grid gird-cols-1 gap-8 md:grid-rows-2 h-full place-content-start place-items-start">
				{displayLogos && (
					<Link to="/">
						<IpweaLogo className="h-20 " />
					</Link>
				)}
				{displayLogos && (
					<a href="https://www.lgnsw.org.au/">
						<LocalGovNSWLogo className="h-20" />
					</a>
				)}
			</div>
		</div>
	)
}

export default AboutUs
