import * as React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby"

import { ArrowNarrowRightIcon } from "@heroicons/react/solid"
import tw from "twin.macro"

const StyledArrowNarrowRightIcon = tw(ArrowNarrowRightIcon)`ml-2 inline h-5 w-5`

const Header = () => (
	<div className="pb-5 sm:flex sm:items-center sm:justify-between max-w-10/12">
		<h3 className="text-4xl font-medium text-gray-900 text-center md:text-left">
			Latest News
		</h3>
		<div className="flex flex-col items-center md:items-end mt-3 sm:mt-0 sm:ml-4 ">
			<Link to="/news" className="btn btn-primary">
				All News <StyledArrowNarrowRightIcon />
			</Link>
		</div>
	</div>
)

const NewsListItem = ({ item }) => {
	const { permalink, authorizedOn, thumbnailImages, name, mediaType } = item
	const linkUrl = `/news/${permalink}`
	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages.childImageSharp)
	const checkImg = image || image !== 0 ? image : null

	return (
		<div className={"sm:flex py-4 border-t border-opacity-80"}>
			<div
				className={
					checkImg
						? "mb-4 flex-shrink-0 sm:mb-0 sm:mr-4"
						: "h-32 w-full sm:w-32 object-cover invisible"
				}
			>
				<Link to={linkUrl}>
					<GatsbyImage
						className="h-32 w-full sm:w-32 object-cover"
						image={image}
						alt={`Thumbnail for news item`}
					/>
				</Link>
			</div>
			<div>
				<p className="text-sm">{mediaType?.replace("_", " ")}</p>
				<h4 className="text-lg font-bold">
					<Link to={linkUrl}>{name}</Link>
				</h4>
				<p className="mt-1">
					{new Intl.DateTimeFormat("en-AU", {
						timeZone: "Australia/NSW",
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(authorizedOn))}
				</p>
			</div>
		</div>
	)
}

const RenderedNewsList = ({ list }) => {
	return (
		<section className="pt-8">
			<Header></Header>
			{list.map(item => (
				<NewsListItem key={item.newsId} item={item} />
			))}
		</section>
	)
}

const RecentNewsList = () => {
	const data = useStaticQuery(graphql`
		{
			allNewsItem(sort: { fields: authorizedOn, order: DESC }, limit: 6) {
				nodes {
					name
					permalink
					authorizedOn
					newsId
					mediaType
					thumbnailImages {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`)
	return <RenderedNewsList list={data.allNewsItem.nodes} />
}

export default RecentNewsList
