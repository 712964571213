import { Link, graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"

import EventCard from "../event-card"

const HomeEvents = ({ blok }) => {
	const [events, setEvents] = useState([])
	const data = useStaticQuery(graphql`
		{
			allEventItem(
				sort: { order: ASC, fields: from }
				filter: { viewInList: { ne: false } }
			) {
				nodes {
					eventId
					permalink
					eventType
					eventRegion
					name
					location
					abstract
					isOnDemand
					from
					to
					addressCity
					addressState
					thumbnailImages {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`)

	useEffect(() => {
		setEvents(
			data.allEventItem.nodes
				.filter(e => !e.isOnDemand && new Date(e.from) >= new Date())
				.sort((a, b) => new Date(a.from) - new Date(b.from))
				.slice(0, 6)
		)
	}, [])

	return (
		<div className="bg-gray-200 px-2 md:px-16 lg:px-28">
			<div className="flex flex-col items-center md:flex-row md:justify-between px-4 pt-10 md:pt-16 pb-1">
				<h1 className="text-neutral font-bold text-2xl xl:text-3xl text-center md:text-left md:ml-2 md:text-2xl mb-1 md:mb-4">
					Upcoming Events
				</h1>
				<Link to={"/events"}>
					<button className="w-32 md:w-auto rounded my-2 text-xs bg-primary text-white hover:opacity-80 py-2 md:px-8">
						View All
					</button>
				</Link>
			</div>
			<div className="mx-2 grid gap-8 md:grid-cols-2 lg:grid-cols-3 md:gap-8 lg:gap-6 xl:gap-10 md:mx-auto px-1 text-md text-white pt-2 pb-8 md:pb-24 md:px-auto">
				{events.map((item, idx) => {
					if (
						item.eventType.toLowerCase() === "pd workshops" ||
						item.eventType.toLowerCase() === "online learning"
					) {
						// Training Event
						return <EventCard item={item} key={idx} isTraining={true} />
					}
					return <EventCard item={item} key={idx} />
				})}
			</div>
		</div>
	)
}

export default HomeEvents
