import React from "react"
import { StaticQuery, graphql } from "gatsby"
import DynamicComponent from "./dynamicComponent"

const StaticContent = ({ id }) => (
	<StaticQuery
		query={graphql`
			{
				storyblokEntry(name: { eq: "_Static" }) {
					id
					content
				}
			}
		`}
		render={data => {
			const staticData = JSON.parse(data.storyblokEntry.content)
			if (id) {
				const component = staticData.content.find(c => c.id === id)
				if (!component) return null

				return <DynamicComponent blok={component} />
			}
			return null
		}}
	></StaticQuery>
)

export default StaticContent
