import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { AdjustmentsIcon, ChevronDoubleDownIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, DocumentIcon, FilterIcon, XIcon } from "@heroicons/react/outline"
import Resource from "../resources/resource"
import { Dialog, Menu, Popover } from "@headlessui/react"
import { updateResources } from "./resourcesHelper"
import ResourceFilter from "./resourceFilter"

const AllResourcesList = () => {
	const data = useStaticQuery(graphql`
		query AllFreeResourcesQuery {
			allFreeResources {
				nodes {
					name
					fileName
					description
					authorizedOn
					categories
					author
					file
					permalink
					abstract
					displayOnAllResourcePage
					thumbnail {
						thumbnailUrl
						modified
						url
					}
				}
			}
			
			allResourceInterestGroups {
				nodes {
					fileId
					interestGroups
				}
			}

			allInterestGroup {
				nodes {
					interestGroupId
					name
					permalink
					isRTDFilter
				}
			}
		}
	`)

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const topicParam = searchParams.get('topic');
		
		if (topicParam) {
			const modifiedTopicParam = `RTD - ${topicParam.replace(' and ', ' & ')}`
			setSelectedCategory('Roads & Transport Directorate');
			setTimeout(() => setSelectedFilters({ ...selectedFilters, topics: [modifiedTopicParam] }));
		} 
	}, []);

	const categoriesWithName = [
		{ categoryName: "All Resources", catId: "All Resources" },
		{ categoryName: "Roads & Transport Directorate", catId: "Roads & Transport Directorate" },
		{ categoryName: "Members Technical Papers Library", catId: "408740002" },
		{ categoryName: "Student Research Library", catId: "408740000" }
	]
	const allResources = updateResources(data)

	const [filterText, setFilterText] = useState("")
	const [filteredResources, setFilteredResources] = useState(allResources)

	const [selectedFilters, setSelectedFilters] = useState({authors: [], topics: [], dates: []})
	const [selectedQuries, setSelectedQuries] = useState({authors: '', topics: '', dates: ''})
	const [filterOptions, setFilterOptions] = useState({authors: [], topics: [], dates: []})

	const [selectedCategory, setSelectedCategory] = useState('All Resources')
	const [catFilteredResources, setCatFilteredResources] = useState([])

	const [isOpen, setIsOpen] = useState(false)

	

	//Global serch functinality
	useEffect(() => {
		// Update filteredResources when filterText or allResources changes
		setFilteredResources(
			(filterText ? catFilteredResources.filter(resource => {
				const lowercaseFilterText = filterText.toLowerCase()
				const lowercaseName = resource.name.toLowerCase()
				const lowercaseCreatedDate = resource.authorizedOn
					? resource.authorizedOn.toLowerCase()
					: ""
				const lowercaseAuthor = resource.author
					? resource.author.toLowerCase()
					: ""

				return (
					lowercaseName.includes(lowercaseFilterText) ||
					lowercaseCreatedDate.includes(lowercaseFilterText) ||
					lowercaseAuthor.includes(lowercaseFilterText)
				)
			}) : catFilteredResources)
		)
	}, [filterText])

	// when user changes categories tab & if filter resources value is updated  Topics, Authors and Years value is also update with updated data 
	const updateFilterOptions = (filtered) => {
		const sortedAuthors = Array.from(
			new Set(filtered.map(resource => resource.author))
		).sort().filter(e => e);

		const sortedTopics = Array.from(
			new Set(
				filtered
				.map(a => a.interestGroups)
				.flat()
				.map(a => a?.name)
			)
		).sort().filter(a => a);

		setFilterOptions({authors: sortedAuthors, topics: sortedTopics, dates: extractUniqueYears(filtered)})
	};

	const formatDate = dateString => {
		const options = { year: "numeric" };
		return new Date(dateString).toLocaleDateString("en-US", options);
	};

	const extractUniqueYears = (resources) => {
		const yearsSet = new Set(resources.map(resource => formatDate(resource.authorizedOn)));
		return Array.from(yearsSet).sort((a, b) => b - a); // Sort years in descending order
	}

	useEffect(() => {
		setSelectedQuries({authors: '', topics: '', dates: ''})
		setSelectedFilters({authors: [], topics: [], dates: []})
	}, [selectedCategory])

	useEffect(() => {
		let filtered = []
		
		if(selectedCategory) {
			if(selectedCategory === 'All Resources') {
				filtered = allResources.filter(group => group.displayOnAllResourcePage).map(res => {
					res.interestGroups = res?.interestGroups?.filter(int => !(int?.isRTDFilter))
					return res
				});
			} else if (selectedCategory === 'Roads & Transport Directorate') {
				filtered = allResources
					.filter(group => group.displayOnAllResourcePage)
					.map(res => {
						res.interestGroups = Array.isArray(res.interestGroups) && res.interestGroups.length > 0
							? res.interestGroups.filter(int => int?.isRTDFilter)
							: [];
						
						// Skip the resource if interestGroups is empty after filtering
						if (res.interestGroups.length === 0) {
							return null; // or return an empty object 
						}

						return res;
					}).filter(res => res !== null); 
			} else {
				filtered = allResources.filter(
					(res) => res.categories && res.categories.includes(selectedCategory)
				);
			}

			updateFilterOptions(filtered)
		}
		
		filtered = (selectedCategory ? filtered : allResources).filter(resource => {
			const matchesAuthor = selectedFilters.authors.length === 0 || selectedFilters.authors.includes(resource.author);
			const matchesDate = selectedFilters.dates.length === 0 || selectedFilters.dates.includes(formatDate(resource.authorizedOn));
			const matchesTopics = selectedFilters.topics.length === 0 || resource?.interestGroups?.some(g => selectedFilters.topics.includes(g.name));
	
			return matchesAuthor && matchesDate && matchesTopics;
		})
		setCatFilteredResources(filtered);
		setFilteredResources(filtered);
	}, [selectedFilters])

	return (
		<div className="ml-4 mr-4">
			<div className="relative w-full">
				<div className="inline-flex w-2/3">	
					<div className="xl:hidden mt-2">
						<Popover className="relative w-full w-auto">
							{({ open, close }) => (
								<>
									<Popover.Button className="inline-flex w-full justify-center bg-white text-neutral items-center p-2 border border-white-300 rounded-md">
										{categoriesWithName?.find(e => e.catId === selectedCategory)?.categoryName || selectedCategory}
										<ChevronDownIcon className={open ? 'rotate-180 transform w-5' : 'w-5'} />
									</Popover.Button>

									<Popover.Panel
										className='left-0 top-full absolute justify-center mt-2 w-80 z-10 rounded-md shadow-lg bg-white ring-1 ring-white ring-opacity-5'
									>
										<div className="py-1">
											{categoriesWithName.map((category, index) => (
												<a className={`cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 ${selectedCategory === category.catId && 'bg-blue-200'}`}
													onClick={() => {
														close()
														setSelectedCategory(category.catId)
													}}
												>
													{category.categoryName}
												</a>
											))}
										</div>
									</Popover.Panel>
								</>
							)}
						</Popover>
					</div>

					<ul className="hidden xl:flex flex-wrap mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
						{categoriesWithName.map((category, index) => (
							<li className="me-2" key={index}>
								<a
									onClick={() => {
										setSelectedCategory(category.catId)
									}}
									className={`cursor-pointer inline-flex items-center justify-center p-4 pl-0 group ${
									selectedCategory === category.catId
										? 'border-b-2 border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500 font-bold'
										: 'border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-neutral font-bold'
									}`}
								>
									{category.categoryName}
								</a>
							</li>
						))}
					</ul>
				</div>

				<div className="w-1/3 inline-flex float-right mt-2">
					<input
						type="text"
						className="rounded-md z-10 w-5/6 border-0 py-1.5 pl-2 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						placeholder="Search resources by name, date, or author..."
						value={filterText}
						onChange={e => setFilterText(e.target.value)}
					/>
					<ResourceFilter 
						setSelectedFilters={setSelectedFilters}
						setSelectedQuries={setSelectedQuries}
						selectedFilters={selectedFilters}
						selectedQuries={selectedQuries}
						filterOptions={filterOptions}
					/>
				</div>
			</div>

			
			
			<div className="mt-4">
				{filteredResources.length === 0 ? (
					<p className="items-center justify-center">No single match found.</p>
				) : (
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
						{filteredResources.map(r => (
							<Resource
								key={r.file}
								resource={r}
								useCardStyle={true}
								thumbnailUrl={r.thumbnail && r.thumbnail.length > 0  && r.thumbnail[0]?.thumbnailUrl}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default AllResourcesList
