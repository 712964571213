import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import NavLink from "./navLink"

const HomePageCallToAction = ({ blok, isRTDPage }) => {
	const { Icons } = blok

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ")
	}

	return (
		<div>
			<div
				className={classNames(
					isRTDPage ? "bg-primary" : "bg-neutral",
					"w-full min-h-60 md:min-h-52 lg:py-14 flex md:items-center justify-evenly py-8"
				)}
			>
				<div className="flex flex-col grid md:grid-cols-4 md:gap-x-28 md:gap-y-16 gap-10 lg:gap-14">
					{Icons.map((icon, idx) => (
						<NavLink link={icon?.Link} isSocialLink={false} key={idx}>
							<div className="text-white flex flex-row md:flex-col md:text-center lg:flex-row lg:text-left items-center hover:opacity-80">
								<FontAwesomeIcon size="4x" icon={icon.IconName} />
								<p className="text-white text-lg lg:2xl font-light pl-4 md:pl-0 lg:pl-2 md:w-20 lg:w-24">
									{icon?.Label}
								</p>
							</div>
						</NavLink>
					))}
				</div>
			</div>
		</div>
	)
}

export default HomePageCallToAction
