import Card from "./card"

const CardBlock = ({ blok, isRTDPage }) => {
	const cards = blok.cards
	return (
		<div className="md:flex md:flex-wrap md:justify-center md:items-center md:h-full max-w-full md:ml-24 md:mr-24 md:pt-24 md:pb-24">
			<div className="grid grid-cols-1 md:grid-cols-3  lg:grid-cols-4 gap-4">
				{cards.map((card, index) => (
					<Card blok={card} key={index} isRTDPage={isRTDPage} />
				))}
			</div>
		</div>
	)
}

export default CardBlock
