import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"

const RtdFilterList = ({ blok, isRTDPage }) => {
	const { Filters, previewFilters } = blok

	const [sortedItems, setSortedItems] = useState([])
	const [resources, setResources] = useState([])
	const [interestGroupsResources, setInterestGroupsResources] = useState([])
	const data = useStaticQuery(graphql`
		{
			newsItems: allNewsItem {
				nodes {
					newsId
					permalink
					name
					excerpt
					authorizedOn
					thumbnailImages {
						childImageSharp {
							gatsbyImageData
						}
					}
					interestGroups {
						interestGroupId
					}
				}
			}
			resources: allFreeResources {
				nodes {
					abstract
					author
					contentVisibility
					createdDate
					description
					file
					name
					permalink
				}
			}
			resourceGroups: allResourceInterestGroups {
				nodes {
					fileId
					interestGroups
				}
			}
		}
	`)

	const resourceNodes = data.resources.nodes
	const resourceInterestGroups = data.resourceGroups.nodes
	const newsNodes = data.newsItems.nodes

	const filterNews = filters => {
		if (filters.length < 1) return

		newsNodes.forEach(node => {
			if (node.interestGroups.length < 1) return

			// console.log(node.interestGroups)
			const igList = node.interestGroups

			const getMatchingNode = igList
				?.map(ig => {
					if (filters.includes(ig.interestGroupId)) {
						return node
					}
				})
				.filter(res => res !== undefined)

			// console.log(getMatchingNode)

			if (getMatchingNode.length < 1) return

			setInterestGroupsResources(prev => [...prev, ...getMatchingNode])
		})
	}

	const filterResources = filters => {
		if (filters.length < 1) return

		//Do the same for resources
		resourceInterestGroups.forEach(node => {
			if (node.interestGroups.length < 1) return

			const igList = node.interestGroups

			const getMatchingNode = igList
				?.map(ig => {
					if (filters.includes(ig)) {
						return node
					}
				})
				.filter(res => res !== undefined)

			const filteredResources = resourceNodes.filter(node =>
				getMatchingNode?.map(res => res.fileId).includes(node.file)
			)

			if (filteredResources.length < 1) return

			setResources(prev => [...prev, ...filteredResources])
		})
	}

	const sortByDate = () => {
		const combined = [...interestGroupsResources, ...resources]

		const sorted = combined.sort((a, b) => {
			if (!a.authorizedOn || !a.createdDate) {
				return 1
			}

			if (!b.authorizedOn || !b.createdDate) {
				return -1
			}

			return (
				new Date(a.authorizedOn || a.createdDate) -
				new Date(b.authorizedOn || b.createdDate)
			)
		})

		setSortedItems(sorted)
	}

	useEffect(async () => {
		{
			setResources([])
			setInterestGroupsResources([])

			if (process.env?.GATSBY_IS_PREVIEW) {
				try {
					const previewFiltersFlattened = previewFilters?.map(id =>
						id["Id"].toLowerCase()
					)

					filterNews(previewFiltersFlattened)
					filterResources(previewFiltersFlattened)
				} catch (e) {
					// console.log("Preview Error", e)
				}
			} else {
				try {
					const FiltersFlattened = Filters?.map(id => id["Id"].toLowerCase())

					filterNews(FiltersFlattened)
					filterResources(FiltersFlattened)
				} catch (e) {
					// console.log("Error", e)
				}
			}
		}
	}, [])

	useEffect(() => {
		sortByDate()
	}, [interestGroupsResources, resources])

	return (
		<div className="min-h-screen bg-gray-200 divide-y divide-gray-200">
			<div className="grid grid-cols-1">
				{sortedItems.map((item, idx) => (
					<RtdItem item={item} key={idx} />
				))}
			</div>
		</div>
	)
}

const RtdItem = ({
	item: {
		permalink,
		thumbnailImages,
		name,
		authorizedOn,
		excerpt,
		createdDate,
		abstract,
		file,
	},
}) => {
	const linkUrl = file ? `/resources/${permalink}` : `/news/${permalink}`

	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages?.childImageSharp)
	const checkImg = image || image !== 0 ? image : null

	// console.log(createdDate, authorizedOn)
	return (
		<div className="bg-white shadow overflow-hidden mt-5 mb-5 ml-10 mr-10">
			<Link to={linkUrl}>
				<div className="px-4 sm:px-6 w-full h-40">
					<div className="flex flex-col md:flex-row">
						<div className="break-normal w-full">
							<div className="pt-4 flex items-center ">
								<h4 className="text-lg lg:text-lg font-bold text-neutral">
									{name}
								</h4>
							</div>
							<div className="flex flex-row break-normal">
								<div className="flex items-center text-sm text-gray-500">
									<p>
										{authorizedOn ||
											(createdDate &&
												new Intl.DateTimeFormat("en-AU", {
													timeZone: "Australia/NSW",
													year: "numeric",
													month: "long",
													day: "2-digit",
												}).format(
													new Date(authorizedOn ? authorizedOn : createdDate)
												))}
									</p>
								</div>
							</div>
							<div className="flex">
								<p
									className="flex-grow"
									dangerouslySetInnerHTML={{
										__html:
											excerpt?.slice(0, 500 || excerpt?.length) ||
											abstract?.slice(0, 500 || abstract?.length),
									}}
								></p>
							</div>
						</div>

						<div className="mt-4 flex md:flex-shrink-0 justify-center mb-4 mt-4">
							{checkImg && (
								<div className="justify-center">
									<Link to={linkUrl}>
										<GatsbyImage
											className="h-32 w-full sm:w-32 object-cover"
											image={image}
											alt={`Thumbnail for news item`}
										/>
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</Link>
		</div>
	)
}

export default RtdFilterList
