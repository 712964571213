import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"

const DateInformation = ({ isOnDemand, from, to }) => {
	if (isOnDemand) {
		return `On Demand`
	} else {
		const commencingFrom = to
			? new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					month: "short",
					day: "2-digit",
			  }).format(new Date(from))
			: new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "short",
					day: "2-digit",
			  }).format(new Date(from))

		const commencingTo = to
			? new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "short",
					day: "2-digit",
			  }).format(new Date(to))
			: ""

		return to ? (
			<strong className="font-normal">
				{commencingFrom} - {commencingTo}
			</strong>
		) : (
			<strong className="font-normal">{commencingFrom}</strong>
		)
	}
}

const EventCard = ({
	item: {
		permalink,
		thumbnailImages,
		name,
		eventType,
		standardPrice,
		memberPrice,
		isOnDemand,
		eventRegion,
		from,
		to,
		addressCity,
		addressState,
	},
	onDemandOnly,
	isTraining,
}) => {
	const linkUrl = isTraining ? `/training/${permalink}` : `/events/${permalink}`
	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages.childImageSharp)

	const checkImg = image || image !== 0 ? image : null

	return (
		<Link to={linkUrl} className="bg-white ">
			<div className="lg:min-h-90 hover:opacity-80">
				<div className="bg-neutral h-48 w-full">
					{checkImg && (
						<div className="h-48 w-full object-cover">
							<GatsbyImage
								className="h-48 w-full object-cover"
								image={image}
								alt={`Thumbnail for news item`}
							/>
						</div>
					)}
				</div>
				<div className="bg-white py-2 px-2 ">
					<div className="text-gray-900 text-sm px-4 py-6 md:py-3">
						<h4 className="text-lg lg:text-lg font-bold text-neutral mb-2">
							{name}
						</h4>
						<DateInformation
							className="pb-1"
							isOnDemand={isOnDemand}
							from={from}
							to={to}
						/>
						<p className="py-1 text-xs lg:text-sm font-light text-gray-500 pb-1">
							<span className="">{eventType} </span>
						</p>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default EventCard
