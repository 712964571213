import { Link } from "gatsby"
import NavLink from "./navLink"
import RichText from "./richText"

const ImageSection = ({ blok }) => {
	const { content, label, abstract, callToAction, flip, callToActionLink } =
		blok

	if (flip) {
		return (
			<div className="max-w-full flex flex-col md:flex-row justify-between">
				<div className="flex flex-grow flex-col place-self-center place-items-center md:items-start md:place-self-stretch md:justify-evenly md:align-center w-3/4 md:w-1/2 lg:w-1/4 mx-2 md:mx-10 my-2">
					<h2 className="text-neutral text-xl lg:text-2xl xl:text-4xl font-bold my-2">
						{label}
					</h2>
					{abstract?.type ? (
						<RichText blok={blok} content={abstract} />
					) : (
						<div className="font-light text-xs md:text-sm xl:text-lg text-center md:text-left">
							{abstract}
						</div>
					)}

					{callToAction && (
						<NavLink
							link={callToActionLink}
							isSocialLink={false}
							className={
								"text-white rounded my-4 text-sm lg:text-md font-bold w-52 md:w-32 lg:w-40  xl:w-52 p-1 bg-primary hover:opacity-80"
							}
						>
							{callToAction}
						</NavLink>
					)}
				</div>
				<img className="w-full md:w-3/5 h-auto" src={content.filename} />
			</div>
		)
	} else {
		return (
			<div className="max-w-full flex flex-col md:flex-row justify-between bg-neutral">
				<img className="w-full md:w-3/5 h-auto" src={content.filename} />
				<div className="text-white flex flex-grow flex-col place-self-center place-items-center md:items-start md:place-self-stretch md:justify-evenly md:align-center w-3/4 md:w-1/2 lg:w-1/4 mx-2 md:mx-10 my-2">
					<h2 className="text-white text-xl lg:text-2xl xl:text-4xl font-bold my-2">
						{label}
					</h2>
					{abstract?.type ? (
						<RichText blok={blok} content={abstract} />
					) : (
						<div className="text-white  font-light text-xs md:text-sm xl:text-lg text-center md:text-left">
							{abstract}
						</div>
					)}
					{callToAction && (
						<NavLink
							link={callToActionLink}
							isSocialLink={false}
							className={
								"text-white rounded my-4 text-sm lg:text-md font-bold w-52 md:w-32 lg:w-40  xl:w-52 p-1 border-2 border-white hover:bg-primary hover:border-transparent hover:opacity-80"
							}
						>
							{callToAction}
						</NavLink>
					)}
				</div>
			</div>
		)
	}
}

export default ImageSection

//Top
/* <Link
className="text-white rounded my-4 text-sm lg:text-md font-bold w-52 md:w-32 lg:w-40  xl:w-52 p-1 bg-primary hover:opacity-80"
to={href}
>
{callToAction}
</Link> */

//Bottom

/* <Link
className="text-white rounded my-4 text-sm lg:text-md font-bold w-52 md:w-32 lg:w-40  xl:w-52 p-1 border-2 border-white hover:bg-primary hover:border-transparent hover:opacity-80"
to={href}
>
{callToAction}
</Link> */
