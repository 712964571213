import * as Icons from "@fortawesome/free-solid-svg-icons"
import * as React from "react"

import { graphql, useStaticQuery } from "gatsby"

import Footer from "./footer"
import Header from "./header"
import PropTypes from "prop-types"
import { library } from "@fortawesome/fontawesome-svg-core"

const iconList = Object.keys(Icons)
	.filter(key => key !== "fas" && key !== "prefix")
	.map(icon => Icons[icon])

library.add(...iconList)

const Layout = ({ children, ClassNames, isRTDPage }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<Header
				siteTitle={data.site.siteMetadata?.title || `Title`}
				isRTDPage={isRTDPage}
			/>
			<main
				className={
					ClassNames ? ClassNames : "max-w-full mx-auto pb-1 min-h-screen"
				}
			>
				{children}
			</main>
			<Footer isRTDPage={isRTDPage} />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
