import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, navigate, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChevronRightIcon } from "@heroicons/react/solid"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const MembershipList = () => {
	const [plans, setPlans] = useState([])
	const [loaded, setLoaded] = useState(false)

	const data = useStaticQuery(graphql`
		{
			allMemberPlans {
				nodes {
					membershipPlanId
					permalink
					name
					abstract
				}
			}
		}
	`)

	const allPlans = data.allMemberPlans.nodes

	useEffect(async () => {
		if (!loaded) {
			setPlans(await getAllPlans())
			setLoaded(true)
		}
	})

	const getAllPlans = async () => {
		let planArr = allPlans.filter(item => !item.name.includes("Monthly"))
		return planArr
	}

	return (
		<div className="bg-gray-200 max-w-full py-8 md:py-16 xl:py-24 md:px-8 lg:px-32">
			<h1 id="plans" className="text-neutral font-bold text-xl text-center md:text-left md:ml-2 md:text-2xl mb-1 md:mb-4">
				Select a Membership
			</h1>
			<div className="divide-y sm:divide-y-0 md:grid md:grid-cols-3 md:gap-4 lg:gap-8 p-1 ">
				{plans.map((action, actionIdx) => (
					<Link
						key={actionIdx}
						className="focus:ring-2 p-1"
						to={`/membership/${action.permalink}`}
					>
						<div
							key={actionIdx}
							className={classNames(
								actionIdx === 0 ? "" : "",
								actionIdx === 1 ? "" : "",
								actionIdx === plans.length - 2 ? "" : "",
								actionIdx === plans.length - 1 ? "" : "",
								"relative filter drop-shadow-lg md:h-60 flex flex-col justify-center items-center group bg-info p-6 focus-within:ring-2 focus-within:ring-inset  text-white focus-within:ring-primary hover:opacity-80"
							)}
						>
							<span className="mt-2 font-semibold text-center">
								{action.name}
							</span>
							<span
								className="pointer-events-none absolute bottom-6 right-6 text-white"
								aria-hidden="true"
							>
								<FontAwesomeIcon icon={"chevron-right"} />
							</span>
							<p className="w-2/3 md:w-auto my-2 text-xs px-1 md:text-sm pt-2 text-center font-light">
								{action.abstract}
							</p>
						</div>
					</Link>
				))}
			</div>
		</div>
	)
}

export default MembershipList
